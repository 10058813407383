.step_col {
   padding-block: 10px;
   padding-inline: 20px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: stretch;
   text-align: left;
}

.title {
   font-family: 'AthelasItalic';
   font-size: 1rem;
   font-weight: 700;
   font-size: 1.4rem;
}

.step_title {
   font-family: 'AthelasItalic';
   color: #866f5e;
   font-weight: 700;
   font-size: 1.4rem;
}

.step_col button {
   /* min-width: -webkit-fill-available; */
   width: 80%;
   margin-inline: 10%;
}

.code_container {
   max-width: 90%;
   margin-inline: auto;
   margin-block: 15px;
   border-radius: 10px;
}

.code_container p,
.code_container h1 {
   color: #fff;
}

.code_container_row {
   display: block;
   min-height: 100%;
}

.advertisements {
   display: none;
}

.code_container_col {
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.get_code_btn {
   color: #fff !important;
   border-color: #fff !important;
}

.image_brand {
   width: 220px;
   height: 80px;
   object-fit: contain;
   margin-bottom: 20px;
   border-radius: 10px;
}

.image_brand:hover::before {
   box-shadow: 0 0 15px #866f5e;
}

.image_brand:hover {
   color: #866f5e;
   box-shadow: 0 0 5px #866f5e;
}

@media (min-width: 768px) {
   .step_col button {
      width: 60%;
      margin-inline: 20%;
   }

   .code_container {
      max-width: 95%;
      border-radius: 10px;
      padding-block: 0;
   }

   .image_brand {
      width: 210px;
      height: 80px;
      object-fit: contain;
      margin-bottom: 15px;
   }

   .code_container_row {
      display: none;
   }
   
   .advertisements {
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      padding-inline: 10px;
   }
}

@media (min-width: 992px) {
   .step_col {
      padding-inline: 35px;
   }

   .step_col button {
      width: 60%;
      margin-inline: 20%;
   }

   .code_container {
      max-width: 90%;
      min-height: 140px;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: unset;
      padding-block: 0;
   }

   .code_container_row {
      display: none;
   }

   .image_brand {
      width: 210px;
      height: 80px;
      object-fit: contain;
      margin-bottom: 10px;
   }

   .advertisements {
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      padding-inline: 10px;
   }
}

@media (min-width: 1200px) {
   .step_col {
      padding-inline: 35px;
   }
   .step_col button {
      width: 60%;
      margin-inline: 20%;
   }

   .code_container {
      max-width: 85%;
      height: 140px;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: unset;
      padding-block: 0;
   }

   .image_brand {
      width: 210px;
      height: 90px;
      object-fit: contain;
      margin-bottom: 10px;
   }

   .code_container_row {
      display: none;
   }

   .advertisements {
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      padding-inline: 10px;
   }
}

@media (min-width: 1400px) {
   .step_col {
      padding-inline: 35px;
   }

   .step_col button {
      width: 60%;
      margin-inline: 20%;
   }

   .code_container {
      max-width: 1200px;
      height: 140px;
      margin-block: 35px;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: unset;
      padding-block: 0;
   }

   .image_brand {
      width: 220px;
      height: 100px;
      object-fit: contain;
      margin-bottom: 10px;
   }

   .code_container_row {
      display: none;
   }

   .advertisements {
      display: flex;
      flex-direction: row;
      padding-top: 10px;
      padding-inline: 10px;
   }
}
